/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { leadershipBios, businessDevBios } from '../content/employees/leadershipBios';
import Image from '../components/ui/Image';
import ProfileCard from '../components/ui/ProfileCard';
import ProfileModal from '../content/employees/ProfileModal';
import TeamCard from '../content/employees/TeamCard';

import {
  header,
  textWrap,
  outerWrap,
  lineStyles,
  leader,
  horizontalLine,
  footerTexter,
} from './leadershipStyles';
import GetInTouch from '../content/partners/GetInTouch';

export default class Team extends Component {
  state = {
    profileModal: false,
  };
  render() {
    const { profileModal } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>Team | DocProbe</title>
          <meta
            name='description'
            content='A leadership that inspires excellence. A team that serves with passion'
          />
        </Helmet>
        <div css={outerWrap}>
          <div className='relative'>
            <div css={lineStyles} />
            <div css={leader}>Leadership</div>
            <div css={textWrap}>
              <span>A leadership that inspires excellence.</span> A team that serves with passion.
            </div>
          </div>
        </div>
        <div css={horizontalLine} />
        <div className='container' css={{ maxWidth: 1230, marginBottom: 100, position: 'static' }}>
          <h2 css={header}>Leadership</h2>
          <div>
            {leadershipBios.map((i) => (
              <ProfileCard info={i} key={i.name} />
            ))}
          </div>
          <h2 css={header}>Business Development</h2>
          <div>
            {businessDevBios.map((i) => (
              <ProfileCard info={i} key={i.name} />
            ))}
          </div>
        </div>
        {profileModal && (
          <ProfileModal closeModal={() => this.setState({ profileModal: !profileModal })} />
        )}
        <GetInTouch />
      </Fragment>
    );
  }
}
